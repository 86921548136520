// eslint-disable-next-line
import * as common from '@/store/common'

const loginErrorGet = (state) => {
  return state.loginError
}

const otpModalGet = (state) => {
  return state.otpModal
}

const otpErrorGet = (state) => {
  return state.otpError
}

const authPageGet = (state) => {
  return state.authPage
}

const userNameGet = (state) => {
  return state.userName
}

const userAppsGet = (state) => {
  return state.userRights
}

export default {
  loginErrorGet,
  otpModalGet,
  otpErrorGet,
  authPageGet,
  userNameGet,
  userAppsGet
}
