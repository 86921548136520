// eslint-disable-next-line
import * as common from '@/store/common'

const LOGIN_ERROR = (state, loginError) => {
  state.loginError = loginError
}

const SET_AUTH_PAGE = (state, page) => {
  state.authPage = page
}

const OTP_MODAL = (state, otpModal) => {
  state.otpModal = otpModal
}

const SET_LAST_SIGN = (state, sign) => {
  state.lastSign = sign
}

const OTP_ERROR = (state, otpError) => {
  state.otpError = otpError
}

const SET_USER = (state, data) => {
  state.userName = data.userName || ''
  state.userRights = data.userRights || []
}

export default {
  LOGIN_ERROR,
  SET_AUTH_PAGE,
  OTP_MODAL,
  SET_LAST_SIGN,
  OTP_ERROR,
  SET_USER
}
