import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  loginError: false,
  otpError: false,
  authPage: 0,
  otpModal: false,
  userName: '',
  userRights: []
}

export default {
  state,
  getters,
  actions,
  mutations
}
