// eslint-disable-next-line
import * as common from '@/store/common'

const SET_WSC_STATE = (state, wscState) => {
  state.wscState = wscState
}

const ALERT_SNACKBAR = (state, snackbar) => {
  if (state.alertSnackbar.active) {
    state.alertSnackbar = { active: false, text: '', color: '', timeout: 5000 }
    setTimeout(() => {
      state.alertSnackbar = snackbar ? { active: true, text: snackbar.text, color: snackbar.color, timeout: 5000 } : { active: false, text: '', color: '', timeout: 5000 }
    }, 100)
  } else {
    state.alertSnackbar = snackbar ? { active: true, text: snackbar.text, color: snackbar.color, timeout: 5000 } : { active: false, text: '', color: '', timeout: 5000 }
  }
}

const ADD_MSG_TO_QUEUE = (state, msg) => {
  state.msgQueue.push(msg)
}

const RESET_MSG_QUEUE = (state) => {
  state.msgQueue = []
}

export default {
  SET_WSC_STATE,
  ALERT_SNACKBAR,
  ADD_MSG_TO_QUEUE,
  RESET_MSG_QUEUE
}
