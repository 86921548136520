import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules/'

Vue.use(Vuex)

export default function createStore () {
  const store = new Vuex.Store({
    modules,
    strict: false
  })
  // Automatically run the `init` action for every module,
  // if one exists.
  for (const moduleName of Object.keys(modules)) {
    if (modules[moduleName].actions.init) {
      store.dispatch(`${moduleName}/init`)
    }
  }
  store.state.Root = store
  return store
}
