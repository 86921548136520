import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  wscState: 0,
  alertSnackbar: { active: false, text: '', color: 'info' },
  msgQueue: []
}

export default {
  state,
  getters,
  actions,
  mutations
}
