<template>
  <div id="view-login">
    <v-form id="formauth" ref="formauth" lazy-validation action="" @submit="submitForm" onsubmit="return false">
      <div id="form-top">
        <v-fade-transition mode="out-in">
          <div v-if="authPageGET === 0" id="form-bottom-line-one" class="form-bottom-line">
            <v-row justify="center" align="end" class="fill-height ma-0">
              <v-col cols="10" sm="6" md="4" lg="2" xl="2" class="pa-0">
                <v-text-field
                  autofocus
                  required
                  validate-on-blur
                  label="Телефон"
                  type="tel"
                  v-mask="'#-###-###-##-##'"
                  v-model="phone"
                  :rules="formRules.phone"
                  autocomplete="tel"
                  :error="loginErrorGET"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-fade-transition>
        <v-slide-y-reverse-transition mode="out-in">
          <div v-if="authPageGET === 1" id="form-bottom-line-two" class="form-bottom-line">
            <v-row justify="center" align="end" class="fill-height ma-0">
              <v-col cols="10" sm="6" md="4" lg="2" xl="2" class="pa-0">
                <v-text-field
                  autofocus
                  required
                  validate-on-blur
                  id="inputPassword"
                  label="Пароль"
                  name="password"
                  v-model="password"
                  :rules="formRules.password"
                  autocomplete="off"
                  type="password"
                  :error="loginErrorGET"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-slide-y-reverse-transition>
        <v-slide-y-reverse-transition mode="out-in">
          <div v-if="authPageGET === 2" id="form-bottom-line-three" class="form-bottom-line">
            <v-row justify="center" align="end" class="fill-height ma-0">
              <v-col cols="10" sm="6" md="4" lg="2" xl="2" class="pa-0">
                <v-text-field
                  autofocus
                  required
                  validate-on-blur
                  label="CODE"
                  type="tel"
                  v-mask="'######'"
                  v-model="code"
                  :rules="formRules.code"
                  autocomplete="off"
                  :error="loginErrorGET"
                  @input="LoginError({ ok: true })"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-slide-y-reverse-transition>
      </div>
      <div id="form-bottom">
        <v-row justify="center" align="start" class="fill-height ma-0">
          <v-col cols="10" sm="6" md="4" lg="2" xl="2" class="pa-0">
            <v-expand-transition>
              <v-btn type="submit" :disabled="loginErrorGET && authPageGET > 1" outlined block color="info" v-if="phone && (/^7|8-\d{3}-\d{3}-\d{2}-\d{2}$/.test(phone))">
                Продолжить
              </v-btn>
            </v-expand-transition>
            <v-expand-transition>
              <v-btn type="button" outlined block color="error" @click="LoginError({ ok: true }); SetAuthPage(0)" class="mt-2" v-if="loginErrorGET && authPageGET === 2">
                Заново
              </v-btn>
            </v-expand-transition>
          </v-col>
        </v-row>
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mask } from 'vue-the-mask'

export default {
  name: 'Login',
  components: {
  },
  directives: {
    mask
  },
  beforeMount: function () {
    this.$nextTick(function () {
    })
  },
  mounted: function () {
    const vm = this
    this.$nextTick(function () {
      vm.$router.currentRoute.name === 'Home' ? vm.page = 0 : vm.page = 1
    })
  },
  data () {
    return {
      phone: '',
      phoneNormal: '',
      password: '',
      passwordFix: '',
      code: '',
      formRules: {
        phone: [v => /^7|8-\d{3}-\d{3}-\d{2}-\d{2}$/.test(v) || 'Введите корректный номер телефона'],
        password: [v => /^[a-zA-Z0-9]{8}$/.test(v) || 'Введите пароль'],
        code: [v => /^\d{6}$/.test(v) || 'Введите CODE']
      }
    }
  },
  computed: {
    ...mapGetters({
      authPageGET: 'auth/authPageGet',
      loginErrorGET: 'auth/loginErrorGet'
    })
  },
  methods: {
    ...mapActions({
      WscSend: 'app/WscSend',
      SetAuthPage: 'auth/SetAuthPage',
      SendAuthData: 'auth/SendAuthData',
      LoginError: 'auth/LoginError'
    }),
    submitForm: function () {
      const vm = this
      if (vm.$refs.formauth.validate()) {
        switch (vm.authPageGET) {
          case 0:
            vm.phoneNormal = '7' + vm.phone.replace(/-/g, '').substr(1)
            vm.WscSend({ action: 'auth/setPhone', data: { phone: vm.phoneNormal } })
            vm.SetAuthPage(1)
            break
          case 1:
            vm.passwordFix = vm.password
            vm.SetAuthPage(2)
            break
          case 2:
            vm.SendAuthData({
              phone: vm.phoneNormal,
              password: vm.passwordFix,
              code: vm.code
            })
            break
        }
      }
    }
  },
  watch: {}
}
</script>

<style scoped>
#view-login{
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
}
#formauth{
  position: relative;
  width: 100%;
  height: 100%;
}
#form-top{
  position: absolute;
  left: 0;
  top: 0;
  height: 42vh;
  width: 100vw;
}
#form-bottom{
  position: absolute;
  left: 0;
  bottom: 0;
  height: 58vh;
  width: 100vw;
}
.form-bottom-line{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100vw;
}
#form-bottom-line-one{
  z-index: 101 !important;
}
#form-bottom-line-two{
  z-index: 102 !important;
}
#form-bottom-line-three{
  z-index: 103 !important;
}
#form-button{
  transition: all 0.35s cubic-bezier(0.88, -0.75, 0.265, 1.55) 0s;
  transform: scale3d(0.95, 0.95, 1);
}
#form-button:hover{
  transform: scale3d(1, 1, 1);
}
#form-button path{
  transition: all 0.65s cubic-bezier(0.88, -0.75, 0.265, 1.55) 0s;
  stroke: #000000;
  stroke-width: 0;
}
#form-button:hover path{
  stroke: #000000;
  stroke-width: 5;
}
#form-button circle{
  transition: all 0.5s cubic-bezier(0.88, -0.75, 0.265, 1.55) 0s
}
#form-button:hover circle{
  r: 90;
}
#form-button:active{
  transform: scale3d(0.95, 0.95, 1);
}
</style>
