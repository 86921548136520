// eslint-disable-next-line
import * as common from '@/store/common'

const SendAuthData = ({ dispatch }, authData) => {
  const signBase = common.SHA(authData.code)
  const passHash = common.SHA(authData.password)
  localStorage.setItem('gdffde6686tSR4', signBase)
  localStorage.setItem('jghDDYT%6toiuy', passHash)
  const sign = common.AES.AES.encrypt(authData.password, common.SIG(signBase)).toString()
  dispatch('app/WscSend', {
    action: 'auth/login',
    data: {
      phone: authData.phone,
      sign: sign
    }
  }, { root: true })
}

const SetToken = ({ commit, dispatch }, data) => {
  localStorage.setItem('GFyr6TU6ydy5E', data.token)
  commit('LOGIN_ERROR', false)
  dispatch('auth/RequestUser', null, { root: true })
}

const RequestUser = ({ dispatch }) => {
  dispatch('app/WscSend', { action: 'auth/getUser', data: common.AES.AES.encrypt(JSON.stringify({ check: 'auth/getUser' }), common.SIG(localStorage.getItem('gdffde6686tSR4'))).toString() }, { root: true })
}

const LoginError = ({ commit }, data) => {
  commit('LOGIN_ERROR', !data.ok)
}

const SetAuthPage = ({ commit }, page) => {
  commit('SET_AUTH_PAGE', page)
}

const DoLogin = ({ commit, dispatch }) => {
  commit('OTP_MODAL', false)
  try {
    localStorage.removeItem('GFyr6TU6ydy5E')
    dispatch('auth/SetAuthPage', 0, { root: true })
    if (common.router.currentRoute.path !== '/login') { common.router.push('/login') }
  } catch (e) { }
}

const PasswordSended = ({ dispatch }) => {
  dispatch('auth/SetAuthPage', 1, { root: true })
}

const DoOTP = ({ commit }) => {
  commit('OTP_MODAL', true)
}

const CheckOTP = ({ commit, dispatch, state }, code) => {
  if (!state.lastSign) { commit('SET_LAST_SIGN', localStorage.getItem('gdffde6686tSR4')) }
  const signBase = common.SHA(code)
  localStorage.setItem('gdffde6686tSR4', signBase)
  const sign = common.AES.AES.encrypt(common.SIG(state.lastSign), common.SIG(signBase)).toString()
  dispatch('app/WscSend', {
    action: 'auth/checkOTP',
    data: {
      sign: sign
    }
  }, { root: true })
}

const OtpOk = ({ commit, dispatch }, data) => {
  commit('OTP_ERROR', false)
  commit('SET_LAST_SIGN', null)
  commit('OTP_MODAL', false)
  dispatch('auth/SetToken', data, { root: true })
}

const OtpError = ({ commit }, data) => {
  commit('OTP_ERROR', !data.ok)
}

const SetUser = ({ commit, dispatch }, data) => {
  try {
    if (data.userName) {
      commit('SET_USER', data)
      dispatch('app/AlertSnackbar', { text: ('Добро пожаловать ' + data.userName), color: '#2196f3' }, { root: true })
      if ((common.router.currentRoute.path === '/login')) { common.router.push('/') }
    } else {
      commit('SET_USER', {})
    }
  } catch (e) {
    commit('SET_USER', {})
  }
}

const UserLogout = ({ commit, dispatch }) => {
  localStorage.removeItem('GFyr6TU6ydy5E')
  localStorage.removeItem('gdffde6686tSR4')
  dispatch('app/WscSend', { action: 'auth/getUser' }, { root: true })
  commit('SET_USER', { userName: '', userRights: [] })
}

export default {
  SendAuthData,
  SetToken,
  RequestUser,
  LoginError,
  SetAuthPage,
  DoLogin,
  PasswordSended,
  DoOTP,
  CheckOTP,
  OtpOk,
  OtpError,
  SetUser,
  UserLogout
}
