<template>
  <div class="view-home">
    <div>
      <v-container>
        <v-row justify="center" class="mt-8">
          <v-hover v-for="(app, index) in userMenu" :key="'app' + index" v-slot="{ hover }">
            <v-card :elevation="hover ? 12 : 2" :style="'opacity: ' + (hover ? 0.7 : 1)" width="200" height="200" class="ma-4" :href="'/' + app.name">
            <v-responsive :aspect-ratio="1">
              <v-row justify="center" class="ma-0 pt-6" style="color: #616161;">
                <i :class="app.icon" style="font-size: 120px;"></i>
                <v-col clos="12" class="text-center subtitle-1">
                  {{ app.title }}
                </v-col>
              </v-row>
            </v-responsive>
            </v-card>
          </v-hover>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Home',
  components: {
  },
  mounted: function () {
    const vm = this
    vm.$nextTick(function () {
    })
  },
  data () {
    return {
      apps: [
        { name: 'prisma', title: 'Призма', icon: 'fab fa-atlassian' },
        { name: 'stats', title: 'Статистика', icon: 'fas fa-chart-bar' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      userAppsGET: 'auth/userAppsGet'
    }),
    userMenu: function () {
      return this.apps.filter(a => this.userAppsGET.includes(a.name))
    }
  },
  methods: {
    ...mapActions({
      WscSend: 'app/WscSend',
      AlertSnackbar: 'app/AlertSnackbar'
    })
  },
  watch: {}
}
</script>

<style>
</style>
