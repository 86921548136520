// eslint-disable-next-line
import * as common from '@/store/common'

const wscStateGet = (state) => {
  return state.wscState
}

const alertSnackbarGet = (state) => {
  return state.alertSnackbar
}

const alertSnackbarActiveGet = (state) => {
  return state.alertSnackbar.active
}

export default {
  wscStateGet,
  alertSnackbarGet,
  alertSnackbarActiveGet
}
