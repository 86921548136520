<template>
  <v-app>
    <v-main>
      <v-system-bar v-if="userNameGET" window height="30" dark color="grey darken-1">
        <v-spacer></v-spacer>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-chip dark small v-on="on">{{ userNameGET }}</v-chip>
          </template>
          <v-list>
            <v-list-item @click="UserLogout">
              <v-list-item-title>Выход</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-system-bar>
      <router-view />
    </v-main>
    <v-dialog persistent :value="otpModalGET" width="500">
      <v-card>
        <v-card-title class="title text-xs-center red darken-4 white--text" style="justify-content: center;">
          Проверка кода
        </v-card-title>
        <v-form lazy-validation action="" @submit="CheckOTP(code)" onsubmit="return false">
          <v-row class="fill-height ma-0">
            <v-col cols="8" class="px-3 py-2">
              <v-text-field
                autofocus
                required
                validate-on-blur
                label="CODE"
                type="tel"
                v-mask="'######'"
                v-model="code"
                :rules="codeRules"
                autocomplete="off"
                :error="otpErrorGET"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4" class="px-3 py-5 text-right">
              <v-btn :disabled="!/^\d{6}$/.test(code)" type="submit" outlined color="info">Отправить</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="alertSnackbarActive" bottom :color="alertSnackbarGET.color" style="z-index: 9999;" :timeout="5000">
      {{  alertSnackbarGET.text }}
      <template v-slot:action="{}">
        <v-btn dark small icon @click="alertSnackbarActive = false">
          <v-icon dark small>fas fa-times</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mask } from 'vue-the-mask'

export default {
  name: 'App',
  components: {
  },
  directives: {
    mask
  },
  beforeMount: function () {
    this.wscListeners()
  },
  mounted: function () {
    this.$nextTick(function () {
    })
  },
  data () {
    return {
      code: '',
      codeRules: [v => /^\d{6}$/.test(v) || 'Введите CODE']
    }
  },
  computed: {
    ...mapGetters({
      wscStateGET: 'app/wscStateGet',
      userNameGET: 'auth/userNameGet',
      userRoutesGET: 'auth/userRoutesGet',
      alertSnackbarGET: 'app/alertSnackbarGet',
      alertSnackbarActiveGET: 'app/alertSnackbarActiveGet',
      otpModalGET: 'auth/otpModalGet',
      otpErrorGET: 'auth/otpErrorGet'
    }),
    alertSnackbarActive: {
      get: function () {
        return this.alertSnackbarActiveGET
      },
      set: function () {
        return this.AlertSnackbar()
      }
    }
  },
  methods: {
    ...mapActions({
      WscSend: 'app/WscSend',
      SetWscState: 'app/SetWscState',
      DoAction: 'app/DoAction',
      AlertSnackbar: 'app/AlertSnackbar',
      CheckOTP: 'auth/CheckOTP',
      RequestUser: 'auth/RequestUser',
      UserLogout: 'auth/UserLogout'
    }),
    wscListeners: function () {
      this.$nextTick(function () {
        if (!this.wscStateGET) {
          this.$wsc.onopen = this.wscOpen
          this.$wsc.onmessage = this.wscMsg
          this.$wsc.onclose = this.wscClose
          this.SetWscState(1)
        }
      })
    },
    wscOpen: function () {
      this.RequestUser()
    },
    wscMsg: function (evt) {
      try {
        const json = JSON.parse(evt.data)
        this.DoAction(json)
      } catch (e) {}
    },
    wscClose: function () {
      const vm = this
      if (this.wscStateGET) {
        vm.SetWscState(0)
        setTimeout(() => {
          vm.$wscReconnect(vm.$wscUrl)
          vm.wscListeners()
        }, 1000)
      }
    },
    isCurrRoute: function (route) {
      return this.$router.currentRoute.name === route
    }
  },
  watch: {
    otpModalGET: function () {
      if (this.otpModalGET) { this.code = '' }
    }
  }
}
</script>

<style>
</style>
