// eslint-disable-next-line
import * as common from '@/store/common'

const WscSend = ({ commit, dispatch, rootState }, msg) => {
  try {
    msg.token = localStorage.getItem('GFyr6TU6ydy5E')
    if (!msg.token && !/^auth\/(setPhone)|(login)$/.test(msg.action)) {
      dispatch('auth/DoLogin', null, { root: true })
      return
    }
    if (!/^auth\/.+$/.test(msg.action) && msg.data && typeof msg.data === 'object') {
      msg.data = common.AES.AES.encrypt(JSON.stringify(msg.data), common.SIG(localStorage.getItem('gdffde6686tSR4'))).toString()
    }
    if (rootState.Root._vm.$wsc.readyState) {
      rootState.Root._vm.$wsc.send(JSON.stringify(msg))
      if (rootState.app && typeof rootState.app.msgQueue === 'object' && rootState.app.msgQueue.length) {
        rootState.app.msgQueue.forEach(msgQ => {
          rootState.Root._vm.$wsc.send(JSON.stringify(msgQ))
        })
        commit('RESET_MSG_QUEUE')
      }
    } else {
      commit('ADD_MSG_TO_QUEUE', msg)
    }
  } catch (e) { }
}

const SetWscState = ({ commit }, wscState) => {
  commit('SET_WSC_STATE', wscState)
}

const DoAction = ({ dispatch }, json) => {
  // console.log('RAW:', json)
  try {
    if (json.data && typeof json.data === 'string') {
      json.data = JSON.parse(common.AES.AES.decrypt(json.data, common.SIG(localStorage.getItem('gdffde6686tSR4'))).toString(common.AES.enc.Utf8))
    }
  } catch (e) { }
  // console.log('DECRYPTED:', json)
  dispatch(json.action, json.data, { root: true })
}

const ShowMessage = ({ dispatch }, data) => {
  dispatch('AlertSnackbar', { text: data.text, color: data.color })
}

const AlertSnackbar = ({ commit }, snackbar) => {
  commit('ALERT_SNACKBAR', snackbar)
}

export default {
  WscSend,
  SetWscState,
  DoAction,
  ShowMessage,
  AlertSnackbar
}
